@font-face {
  font-family: 'Rubik';
  src: url('assets/fonts/Rubik-Regular.ttf');
  font-weight: normal;
}

@font-face {
  font-family: 'Rubik';
  src: url('assets/fonts/Rubik-Bold.ttf');
  font-weight: bold;
}

* {
  box-sizing: border-box;
  /* global text color */
  color: #424242;
}

html, body, #root {
  margin: 0;
  min-height: 100vh;
}

h1, h2, h3, h4, h5, h6 {
  margin: 10px 0;
}

a {
  text-decoration: unset;
  color: inherit;
  cursor: pointer;
}

a:focus {
  outline: unset;
}

fieldset {
  padding: 0;
  border: unset;
}

button, input, textarea {
  font: unset;
  font-size: 15px;
}

#root, .ql-container {
  display: flex;
  font-family: 'Rubik', sans-serif;
  background-color: #f8f9fd;
  font-size: 15px;
}

.react-datepicker-popper {
  z-index: 2;
}

.react-datepicker-wrapper {
  width: 100%;
}

.react-datepicker__input-container input {
  border-radius: 15px;
  border: 1px solid #bfc1c4;
  padding: 7px 15px;
  width: 100%;
  margin-top: 5px;
}

.react-datepicker__input-container input:focus {
  outline: unset;
}

.react-datepicker__input-container .with-error {
  border: 1px solid #FF004E;
}

.ql-container.ql-snow {
  background-color: #ffffff;
  border-radius: 0 0 15px 15px;
  border: 1px solid #bfc1c4;
  padding: 10px;
}

.ql-toolbar.ql-snow {
  background-color: #ffffff;
  border-radius: 15px 15px 0 0;
  border: 1px solid #bfc1c4;
  padding: 10px 20px;
}

.ql-toolbar.ql-snow button {
  margin: 0 2px;
}

.ql-active {
  background-color: #bfc1c4 !important;
  border-radius: 4px;
}

.ql-active path, .ql-active line, .ql-active rect {
  stroke: #ffffff !important;
}

.ql-toolbar button:hover .ql-stroke,
.ql-toolbar button:hover line,
.ql-toolbar button:hover rect {
  stroke: #313131 !important;
}

.ql-toolbar button.ql-active:hover .ql-stroke,
.ql-toolbar button.ql-active:hover line,
.ql-toolbar button.ql-active:hover rect {
  stroke: #ffffff !important;
}

.ql-toolbar.ql-snow .ql-formats:not(:last-child) {
  margin-right: 5px;
  padding-right: 5px;
  border-right: 1px solid #bfc1c4;
}

.wysiwyg-read-only .ql-toolbar {
  display: none;
}

.wysiwyg-read-only .ql-container {
  border: 1px solid #bfc1c4 !important;
  border-radius: 15px;
  background-color: #ffffff;
  pointer-events: none;
}

.custom-tooltip {
  background-color: white !important;
  border-radius: 15px 15px 15px 0;
  border: 1px solid #bfc1c4 !important;
  margin-top: -25px !important;
  margin-left: -2px !important;
  color: #313131 !important;
  max-width: 400px;
}

.custom-tooltip::before, .custom-tooltip::after {
  display: none !important;
}

.custom-tooltip-right {
  border-radius: 15px 15px 0 15px;
}

.react-json-view {
  margin: 15px 0;
  border-radius: 15px;
  padding: 10px;
};
